.meetup-edit {
  &__dropzone-wrapper {
    display: flex;
    justify-content: space-between;
  }
  &__container {
    width: 46%;
    height: 175px;
    position: relative;
  }

  &__preview__topic-img {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    display: block;
    width: 100%;
    height: 175px;
    object-fit: cover;
    border-radius: $border-radius;
  }

  &__button-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 175px;
    text-align: center;
    background: rgba(0, 0, 0, 0.4);
    border-radius: $border-radius;
    opacity: 0;
  }

  &__button-container svg {
    position: relative;
    top: calc(50% - 25px);
  }

  &__container:hover &__button-container {
    opacity: 1;
  }

  .speaker {
    margin-bottom: 25px;
    position: relative;
    z-index: 10;
  }

  .public-DraftEditor-content {
    min-height: 150px;
  }
}

.page-content {
  width: $app-content-width;
  margin: 0 auto;
  padding: 60px 0;
}

.page-heading {
  padding-bottom: 50px;
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 28px;
  text-align: center;
  color: $darkgray;
}
