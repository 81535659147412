.meetup-list {
  justify-content: left;

  &__wrapper {
    width: $app-content-width;
    margin: 0 auto;
    padding: 60px 0;

    .loader-container {
      display: flex;
      justify-content: center;
    }
  }

  &__header {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    &__heading {
      margin-bottom: 0;
      font-weight: 700;
      font-size: $font-size-title-xl;
      line-height: 28px;
      color: $darkgray;
    }

    .button.button_outlined-violet {
      width: max-content;
    }
  }

  &__menu {
    margin-bottom: 15px;

    .MuiTabs-wrapper {
      font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
    }

    .MuiTabs-centered {
      border-bottom: 2px solid $whitegray;
    }

    &__item:first-child {
      max-width: 100px !important;
    }

    &__item {
      font-size: 1rem !important;
      min-width: 100px !important;
      text-transform: none !important;
    }
  }

  &__counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;
  }
}

.margin-bottom-50 {
  margin-bottom: 50px;
}

.margin-bottom-24 {
  margin-bottom: 24px;
}

.text-align-center {
  text-align: center;
}

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid #e2e7ef;
}

.nav a {
  display: block;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  opacity: 0.7;
  color: black;
  position: relative;
}

a.active {
  opacity: 1;
}

a.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: #3f51b5;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.nav a:hover {
  background-color: rgba(128, 101, 236, 0.2);
}

.App .button.margin-left-10 {
  margin-left: 10px;
}
.App .button.margin-left-5 {
  margin-left: 5px;
}
