.theme-card {
  @include card;

  &__actions {
    padding: 20px 40px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0;

    &__title {
      margin: 0 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 17px;
      letter-spacing: 0.25px;
      color: $gray-01;
    }

    &__info {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 15px 0 0 20px;
      overflow: hidden;

      &__item {
        position: relative;

        &-divider {
          margin: 0 7px;
        }

        &:not(:first-child) {
          display: inline-block;
          width: 65%;
          position: relative;
          max-width: 50%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px 0 0 0;

      button {
        width: min-content;
        margin-right: 10px;
        padding: 10px;
      }
    }

    &__button:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  &__content {
    margin-bottom: 15px;
    padding: 0 20px;

    &:last-child {
      padding-bottom: 0;
    }

    &__heading {
      margin-bottom: 12px;
      max-height: 40px;
      font-family: 'Rubik', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-text-xxl;
      line-height: 20px;
      color: $darkgray;
      word-break: break-word;
      overflow: hidden;
    }

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 21px;
      color: $gray-02;
      word-break: break-word;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 0 20px 15px 20px;
    justify-content: space-between;

    &__participants {
      display: flex;
      font-size: $font-size-text-l;

      &__image,
      &__count {
        margin-right: 5px;
      }
    }

    &__button {
      color: $white !important;
      border-radius: 20px !important;
      & .button__label {
        height: 0;
      }
    }
    &__like {
      background-color: $purple;
      background-image: -webkit-linear-gradient(64.47deg, $purple 16.63%, #314ad7 83.37%);
      background-image: linear-gradient(25.53deg, $purple 16.63%, #314ad7 83.37%);
    }
    &__dislike {
      background-color: #9370db;
    }
  }
}
