.news-list {
  justify-content: left;

  &__list {
    @include ul-clear;
  }

  &__wrapper {
    width: $app-content-width;
    margin: 0 auto;
    padding: 60px 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px;

    &__heading {
      margin-bottom: 0;
      font-weight: 700;
      font-size: $font-size-title-xl;
      line-height: 28px;
      color: $darkgray;
    }

    .button.button_outlined-violet {
      width: max-content;
    }
  }
}
