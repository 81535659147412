.image-dropzone {
  width: 100%;
  margin-top: 16px;
  border: 1px dashed $gray-08;
  border-radius: $border-radius;
  background-color: rgba(244, 246, 252, 0.2);

  &__upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    color: $gray-01;
    background-color: rgba(244, 246, 252, 0.2);
    outline: none;
    cursor: pointer;
  }

  &__upload-image {
    width: 41px;
    height: 28px;
    margin-top: 10px;
  }

  &__explanation {
    max-width: 200px;
    margin-bottom: 25px;
    font-size: $font-size-text-m;

    span {
      color: $darkblue;
    }
  }

  &__warning {
    color: $gray-03;
    font-size: $font-size-text-s;
    margin: 0 0 3px 0;
  }

  &--edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46%;
    margin-top: 0;
    padding: 5px 10px;
    text-align: center;

    .image-dropzone__upload-container {
      padding: 0;
    }
  }
}
