@mixin notification-custom($urlImg, $dark-color, $color) {
  background: linear-gradient(to right, $dark-color 56px, $color 56px, $color);

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 19px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    margin: 0;
    background-image: url($urlImg);
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
  }
}

.notification-container {
  top: 60px;
  min-width: 560px;
  font-size: $font-size-title-s;
  overflow: hidden;

  .notification-success {
    @include notification-custom('../assets/images/icons/icon-success.svg', $dark-green, $green);
  }

  .notification-error {
    @include notification-custom('../assets/images/icons/icon-error.svg', $dark-red, $red);

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 19px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      margin: 0;
      background-image: url('../assets/images/icons/icon-close.svg');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
    }
  }

  .notification-info {
    @include notification-custom('../assets/images/icons/icon-info.svg', $blue, $lightblue);
  }

  .notification-warning {
    @include notification-custom('../assets/images/icons/icon-warning.svg', $dark-orange, $orange);
  }

  .notification {
    padding: 19px 15px 16px 58px;
    box-shadow: none;
    border-radius: 4px;

    .title {
      margin-right: 5px;
    }
  }

  .notification-message {
    display: flex;
    padding-left: 18px;
  }
}
