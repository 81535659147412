.meetup-create {
  width: $app-content-width;
  text-align: center;
  margin: 0 auto;
  padding: 60px 0;

  .MuiTabs-centered {
    border-bottom: 2px solid $whitegray;
  }

  &__first-tab {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  &__actions {
    padding: 20px 40px;
  }

  &__page-heading {
    margin-top: 30px;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: center;
    color: $black;
  }

  .speaker {
    margin-bottom: 25px;
    position: relative;
    z-index: 10;
  }

  .description {
    margin-top: 40px;
  }

  &__tab-text {
    text-transform: none;
    font-size: 16px;
    margin-left: 15px;
  }

  &__description {
    text-align: center;
    color: $gray-01;
    font-size: 0.875rem;
    line-height: 22px;
    margin-bottom: 16px;
  }

  .image-wrap {
    width: 48%;
    position: relative;
  }

  .default-image {
    width: 100%;
    filter: opacity(50%);
    transition: 1s;
    cursor: pointer;
    border-radius: $border-radius;
  }

  .default-image:hover {
    filter: opacity(100%);
  }

  .active-image {
    width: 100%;
    cursor: pointer;
    border-radius: $border-radius;
  }

  .images-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .image-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }

  .active-icon {
    display: inline;
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .hide-icon {
    display: none;
  }

  .css-1f9w6yw-control {
    outline: none;
    box-shadow: none;
  }

  .MuiInputBase-root {
    padding: 0;
  }

  .MuiOutlinedInput-input {
    padding: 9.5px 16px;
  }

  .MuiOutlinedInput-inputMultiline {
    padding: 18.5px 16px;
  }

  .public-DraftEditor-content {
    min-height: 150px;
  }
}
