.InputPopover {
  &__root {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    width: 350px;
    padding: 4px;
    box-sizing: border-box;
    background: linear-gradient(to bottom, $lightgray 0%, #f6f7f8 100%);
    border: 1px solid $gray-02;
    border-radius: 2px;
    z-index: 5;

    &::before {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      top: -10px;
      left: 10px;
      border: 5px solid transparent;
      border-bottom-color: $gray-02;
    }

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 0;
      height: 0;
      top: -9px;
      left: 10px;
      border: 5px solid transparent;
      border-bottom-color: $lightgray;
    }
  }

  &__inner {
    display: flex;
    align-items: baseline;

    label {
      min-width: 60px;
      margin: 5px 5px;
    }
  }

  &__input {
    display: inline-block;
    flex: 1 0 auto;
    height: 30px;
    padding: 2px 6px;
    margin: 5px 5px;
    font-size: 14px;
    line-height: 24px;
    background: none $white;
    border: 1px solid $gray-02;
    border-radius: 2px;
    box-sizing: border-box;

    &:focus,
    &:active {
      outline: none;
    }
  }

  &__buttonGroup {
    display: flex;
    justify-content: space-between;
    margin: 10px 5px;

    button {
      display: inline-block;
      height: 30px;
      width: 142px;
      margin: 0;
      padding: 3px;
      font-size: inherit;
      font-family: inherit;
      line-height: 22px;
      text-decoration: none;
      white-space: nowrap;
      color: #333;
      box-sizing: border-box;
      background: linear-gradient(to bottom, $lightgray 0%, #f6f7f8 100%);
      border: 1px solid $gray-02;
      border-radius: 2px;
      cursor: pointer;

      &:hover,
      &:active {
        color: $white;
        background: $blue;
      }
    }
  }
}
