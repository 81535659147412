.memo-preview {
  &__topic-name {
    margin: 0;
    padding-bottom: 15px;
    font-style: normal;
    font-size: $font-size-title-l;
    font-weight: 600;
    letter-spacing: -0.33px;
    line-height: 30px;
    overflow-wrap: break-word;
  }

  &__item-wrap:not(:last-child) {
    padding-bottom: 30px !important;
  }

  &__item-wrap b {
    font-weight: 600;
  }

  &__item-wrap br {
    display: none;
  }

  &__description p {
    margin: 4px 0;
    padding: 0;
    font-family: $font-family-roboto;
    font-style: normal;
    font-size: $font-size-text-xl;
    letter-spacing: -0.33px;
    line-height: 22px;
  }
}

.img-arrow {
  height: 19px;
  width: 30px;
}

.memo-main-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.add-memo-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  cursor: pointer;
}

.add-memo-button:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  font-size: 30px;
  color: black;
  border: 2px solid black;
  border-radius: 50%;
}

.plus:before {
  content: '+';
}
