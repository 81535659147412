.news-preview {
  width: $app-content-width;
  text-align: center;
  margin: 0 auto;
  padding: 60px 0;

  &__img-wrapper {
    font-size: 0;
    display: flex;
    justify-content: center;
  }
  &__likes {
    min-height: 70px;
    min-width: 70px;
    max-width: 50px;
    border-radius: 35px;
    background-color: $white;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    top: -40px;
    left: 450px;

    &__count {
      color: $gray-03;
      font-size: 15px;
    }
    &__count.hidden {
      display: none;
    }
  }

  &__header {
    margin: -70px 0 0;
    padding: 30px 25px 20px;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.33px;
    color: $darkgray;
    overflow-wrap: break-word;
  }

  &__description {
    padding: 24px !important;
  }
}
