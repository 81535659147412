.file-card {
  display: flex;
  flex-direction: row;
  font-size: $font-size-title-s;
  line-height: 30px;

  &__container {
    display: flex;
    align-items: center;

    > button {
      display: flex;
      padding: 5px;
      width: 28px;
    }
  }

  &__title {
    max-width: 270px;
    min-width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px;
  }

  &__size {
    display: flex;
    width: 55px;
    padding-right: 10px;
  }
}
