html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $lightgray;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.App {
  min-height: 100%;
  background-color: $lightgray;
  background-image: url('../assets/images/bg-people.png');
  background-position: right bottom -50px; /*Positioning*/
  background-repeat: no-repeat; /*Prevent showing multiple background images*/
  background-attachment: fixed;

  &__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 89%;
  }
}

a {
  text-decoration: none;
}

button {
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: $border-radius;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  width: 152px;
  text-transform: none;
  -webkit-transition-property: color, background-color, box-shadow;
  transition-property: color, background-color, box-shadow;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  outline: none;
}

.button_contained-violet:disabled {
  opacity: 0.5;
  background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%) !important;
}

button:hover {
  background-color: rgba(128, 101, 236, 0.2);
}

.hidden {
  visibility: hidden;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: $gray-01;
  box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(
    top,
    rgba(255, 255, 255, 0) 0%,
    rgba(0, 174, 255, 0.04) 50%,
    rgba(255, 255, 255, 0) 51%,
    rgba(0, 174, 255, 0.03) 100%
  );
}

.word-wrap {
  word-wrap: break-word;
}

.card-avatar {
  background-color: $gray-04;
  font-size: 1rem;
  line-height: 17px;
  margin-right: 10px;

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: $gray-01;
    margin: 0 2px;
  }
}

.loader-wrap {
  text-align: center;
}

.input-label {
  display: flex;
  font-size: $font-size-text-l;
  color: $gray-03;
  line-height: 16px;
  padding-bottom: 6px;
  font-weight: 400;

  &__description {
    margin-top: 12px;
  }
}

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  outline: none;
}
