.admin-panel {
  &__list {
    @include ul-clear;
    border-left: 2px solid $gray-04;
    padding-left: 45px;
  }

  &__list-item {
    padding: 10px 0;
  }

  &__button {
    width: 190px;
  }

  &__wrapper {
    width: $app-content-width;
    margin: 0 auto;
    padding: 60px 0;
  }

  &__header {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center;

    &__heading {
      margin-bottom: 0;
      font-weight: 700;
      font-size: $font-size-title-xl;
      line-height: 28px;
      color: $darkgray;
    }
  }

  &__body {
    padding-left: 140px;
  }
}
