/* typography scheme */
/* 24px */
/* 22px */
/* 18px */
/* 14px */
/* 16px */
/* 14px */
/* 13px */
/* 10px */
/* fonts */
@import url(~react-notifications/lib/notifications.css);
html,
body,
#root {
  height: 100%;
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f6fc; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; }

.App {
  min-height: 100%;
  background-color: #f4f6fc;
  background-image: url("../assets/images/bg-people.png");
  background-position: right bottom -50px;
  /*Positioning*/
  background-repeat: no-repeat;
  /*Prevent showing multiple background images*/
  background-attachment: fixed; }
  .App__main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    height: 89%; }

a {
  text-decoration: none; }

button {
  box-sizing: border-box;
  cursor: pointer;
  margin: 0;
  padding-top: 11px;
  padding-bottom: 11px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  width: 152px;
  text-transform: none;
  -webkit-transition-property: color, background-color, box-shadow;
  transition-property: color, background-color, box-shadow;
  -webkit-transition-duration: 0.4s;
  transition-duration: 0.4s;
  outline: none; }

.button_contained-violet:disabled {
  opacity: 0.5;
  background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%) !important; }

button:hover {
  background-color: rgba(128, 101, 236, 0.2); }

.hidden {
  visibility: hidden; }

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus input:-webkit-autofill,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0;
  -webkit-text-fill-color: #5b6887;
  box-shadow: 0 0 0 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  background: -webkit-linear-gradient(top, rgba(255, 255, 255, 0) 0%, rgba(0, 174, 255, 0.04) 50%, rgba(255, 255, 255, 0) 51%, rgba(0, 174, 255, 0.03) 100%); }

.word-wrap {
  word-wrap: break-word; }

.card-avatar {
  background-color: #c2cbda;
  font-size: 1rem;
  line-height: 17px;
  margin-right: 10px; }
  .card-avatar__title {
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 17px;
    letter-spacing: 0.25px;
    color: #5b6887;
    margin: 0 2px; }

.loader-wrap {
  text-align: center; }

.input-label {
  display: flex;
  font-size: 0.875rem;
  color: #aab6ca;
  line-height: 16px;
  padding-bottom: 6px;
  font-weight: 400; }
  .input-label__description {
    margin-top: 12px; }

.ReactVirtualized__Grid,
.ReactVirtualized__List {
  outline: none; }

.meetup-list {
  justify-content: left; }
  .meetup-list__wrapper {
    width: 550px;
    margin: 0 auto;
    padding: 60px 0; }
    .meetup-list__wrapper .loader-container {
      display: flex;
      justify-content: center; }
  .meetup-list__header {
    margin-bottom: 30px;
    width: 100%;
    display: flex;
    justify-content: center; }
    .meetup-list__header__heading {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 28px;
      color: #3c4861; }
    .meetup-list__header .button.button_outlined-violet {
      width: max-content; }
  .meetup-list__menu {
    margin-bottom: 15px; }
    .meetup-list__menu .MuiTabs-wrapper {
      font-family: Rubik, Roboto, Helvetica, Arial, sans-serif; }
    .meetup-list__menu .MuiTabs-centered {
      border-bottom: 2px solid #e2e7ef; }
    .meetup-list__menu__item:first-child {
      max-width: 100px !important; }
    .meetup-list__menu__item {
      font-size: 1rem !important;
      min-width: 100px !important;
      text-transform: none !important; }
  .meetup-list__counter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px; }

.margin-bottom-50 {
  margin-bottom: 50px; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.text-align-center {
  text-align: center; }

.nav {
  display: flex;
  justify-content: space-around;
  align-items: center;
  border-bottom: 2px solid #e2e7ef; }

.nav a {
  display: block;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  text-align: center;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  opacity: 0.7;
  color: black;
  position: relative; }

a.active {
  opacity: 1; }

a.active:after {
  content: '';
  position: absolute;
  bottom: -2px;
  right: 0;
  height: 2px;
  width: 100%;
  background-color: #3f51b5;
  transition: all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; }

.nav a:hover {
  background-color: rgba(128, 101, 236, 0.2); }

.App .button.margin-left-10 {
  margin-left: 10px; }

.App .button.margin-left-5 {
  margin-left: 5px; }

.App-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  background-image: linear-gradient(5.87deg, #8065ec 16.63%, #314ad7 83.37%);
  padding-left: 3%;
  padding-right: 3%; }
  .App-header__logo {
    padding: 10px 0; }
  .App-header__logo-container {
    width: 30%; }
  .App-header .margin-60 {
    margin-right: 60px !important; }
  .App-header__links {
    width: 30%;
    display: flex;
    justify-content: center; }
    .App-header__links > a {
      padding: 0 30px; }
  .App-header__link {
    width: auto !important;
    color: #ffffff !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-variant: normal !important;
    text-transform: none !important;
    border-radius: 5px 5px 0 0 !important; }

.active-menu {
  border-bottom: 2px solid #ffffff !important; }

.notifications {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  max-width: 48px;
  margin-right: 10px; }
  .notifications > button {
    width: 40px;
    height: 40px;
    padding: 0; }
    .notifications > button > span {
      width: 35px;
      height: 35px; }
  .notifications__unread {
    background-color: #ff380c;
    color: #ffffff;
    border-radius: 8px;
    min-width: 15px;
    height: 15px;
    font-size: 11px;
    position: relative;
    top: -12px;
    left: -15px;
    display: flex;
    justify-content: space-around;
    line-height: 10px;
    align-items: center;
    padding-right: 1px; }

.notificationsPopover .MuiPopover-paper {
  border-radius: 10px !important;
  background-color: #f4f6fc !important; }

.avatar {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center; }
  .avatar__name, .avatar__surname {
    margin-right: 6px;
    color: #ffffff;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.25px;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif; }
  .avatar__image {
    min-width: 40px;
    line-height: 19px;
    letter-spacing: 0.25px;
    background-color: transparent !important;
    overflow: hidden;
    margin-right: 30px;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif; }
    .avatar__image > div {
      font-size: 1rem !important; }
    .avatar__image div:not(.avatar__image) {
      background-color: rgba(255, 255, 255, 0.45); }

.notifications-list {
  display: flex;
  min-width: 400px;
  max-height: 405px;
  background-color: #f4f6fc; }

@media screen and (max-width: 990px) {
  .avatar {
    width: min-content; }
    .avatar__name, .avatar__surname {
      display: none; } }

.meetup-card {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 9px rgba(194, 203, 218, 0.25);
  margin-bottom: 16px;
  color: #6c757d;
  position: relative; }
  .meetup-card__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0; }
    .meetup-card__header__title {
      margin: 0 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 17px;
      letter-spacing: 0.25px;
      color: #5b6887; }
    .meetup-card__header__info {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 15px 0 0 20px;
      overflow: hidden; }
      .meetup-card__header__info__item {
        position: relative; }
        .meetup-card__header__info__item-divider {
          margin: 0 7px; }
        .meetup-card__header__info__item:not(:first-child) {
          display: inline-block;
          width: 65%;
          position: relative;
          max-width: 50%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
    .meetup-card__header__buttons {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px 0 0 0; }
      .meetup-card__header__buttons button {
        width: min-content;
        margin-right: 10px;
        padding: 10px; }
    .meetup-card__header__button:hover {
      background-color: rgba(0, 0, 0, 0.08); }
  .meetup-card__content {
    margin-bottom: 15px;
    padding: 0 20px; }
    .meetup-card__content:last-child {
      padding-bottom: 0; }
    .meetup-card__content__heading {
      margin-bottom: 12px;
      max-height: 60px;
      height: 100%;
      font-family: 'Rubik', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      color: #3c4861;
      word-break: break-word;
      overflow: hidden; }
    .meetup-card__content__text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 20px;
      color: #848fa5;
      word-break: break-word;
      max-height: 40px;
      overflow: hidden; }
  .meetup-card__footer {
    display: flex;
    align-items: center;
    padding: 0 20px 15px 20px;
    justify-content: space-between; }
    .meetup-card__footer__participants {
      display: flex;
      font-size: 0.875rem; }
      .meetup-card__footer__participants__image, .meetup-card__footer__participants__count {
        margin-right: 5px; }

.green-border {
  border-left: 8px solid #80cbc4; }

.meetup-card:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px; }

.theme-card {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 9px rgba(194, 203, 218, 0.25);
  margin-bottom: 16px;
  color: #6c757d; }
  .theme-card__actions {
    padding: 20px 40px; }
  .theme-card__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0; }
    .theme-card__header__title {
      margin: 0 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 17px;
      letter-spacing: 0.25px;
      color: #5b6887; }
    .theme-card__header__info {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 15px 0 0 20px;
      overflow: hidden; }
      .theme-card__header__info__item {
        position: relative; }
        .theme-card__header__info__item-divider {
          margin: 0 7px; }
        .theme-card__header__info__item:not(:first-child) {
          display: inline-block;
          width: 65%;
          position: relative;
          max-width: 50%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden; }
    .theme-card__header__buttons {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px 0 0 0; }
      .theme-card__header__buttons button {
        width: min-content;
        margin-right: 10px;
        padding: 10px; }
    .theme-card__header__button:hover {
      background-color: rgba(0, 0, 0, 0.08); }
  .theme-card__content {
    margin-bottom: 15px;
    padding: 0 20px; }
    .theme-card__content:last-child {
      padding-bottom: 0; }
    .theme-card__content__heading {
      margin-bottom: 12px;
      max-height: 40px;
      font-family: 'Rubik', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 20px;
      color: #3c4861;
      word-break: break-word;
      overflow: hidden; }
    .theme-card__content__text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 21px;
      color: #848fa5;
      word-break: break-word; }
  .theme-card__footer {
    display: flex;
    align-items: center;
    padding: 0 20px 15px 20px;
    justify-content: space-between; }
    .theme-card__footer__participants {
      display: flex;
      font-size: 0.875rem; }
      .theme-card__footer__participants__image, .theme-card__footer__participants__count {
        margin-right: 5px; }
    .theme-card__footer__button {
      color: #ffffff !important;
      border-radius: 20px !important; }
      .theme-card__footer__button .button__label {
        height: 0; }
    .theme-card__footer__like {
      background-color: #8065ec;
      background-image: -webkit-linear-gradient(64.47deg, #8065ec 16.63%, #314ad7 83.37%);
      background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%); }
    .theme-card__footer__dislike {
      background-color: #9370db; }

.card {
  width: 100%;
  margin: 0 0 24px;
  padding: 20px 24px;
  background: #ffffff;
  box-shadow: 0 4px 7px rgba(215, 221, 232, 0.398055);
  border-radius: 5px;
  overflow: visible;
  box-sizing: border-box; }
  .card__heading {
    margin: 0 0 0 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: #848fa5; }
  .card__no-padding {
    padding: 0; }
  .card__10-bottom-margin {
    margin-bottom: 10px !important; }
  .card__time-n-place {
    width: 100%;
    margin: 0 0 15px;
    padding: 20px 25px;
    background: #ffffff;
    box-shadow: 0 4px 7px rgba(215, 221, 232, 0.398055);
    border-radius: 5px;
    overflow: visible;
    box-sizing: border-box; }
  .card__speaker {
    display: flex;
    align-items: center; }
  .card__rate {
    display: flex;
    align-items: center;
    padding: 14px 22px; }
  .card__description {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 30px;
    letter-spacing: -0.33px;
    color: #3c4861;
    white-space: pre-line; }
  .card__actions {
    display: flex;
    align-items: center; }
  .card__main-actions {
    padding-left: 10px !important; }
  .card__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%; }
  .card__participants {
    padding: 17px; }
    .card__participants span:not(:first-child) .sb-avatar.sb-avatar--text {
      padding-left: 10px; }

.full-rated-star {
  background-image: url("../assets/images/icons/star-full-violet.svg"); }

.empty-rated-star {
  background-image: url("../assets/images/icons/star-empty-violet.svg"); }

.full-unrated-star {
  background-image: url("../assets/images/icons/star-full-grey.svg"); }

.empty-unrated-star {
  background-image: url("../assets/images/icons/star-empty-grey.svg"); }

.preview__topic-name {
  margin: 0;
  padding: 30px 25px 0;
  font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.33px;
  color: #3c4861;
  overflow-wrap: break-word; }

.preview__topic-img-wrapper {
  font-size: 0;
  display: flex;
  justify-content: center; }

.preview__topic-img {
  width: 100%;
  height: 308px;
  object-fit: cover;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px; }

.preview__time-n-place-item {
  display: flex;
  list-style-type: none;
  margin-bottom: 17px;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  letter-spacing: -0.2px;
  color: #3c4861;
  overflow-wrap: break-word; }
  .preview__time-n-place-item__place {
    width: calc(550px - 100px); }

.preview__time-n-place-item.red {
  color: #ff380c; }

.preview__time-n-place-time-separator {
  padding: 0 5px; }

.preview__time-n-place-item:last-child {
  margin-bottom: 0; }

.preview__time-n-place-icon {
  display: inline-block;
  vertical-align: middle;
  margin-right: 15px; }

.preview__speaker-name {
  margin: 0 0 0 13px;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 19px;
  color: #3c4861; }

.preview__rating {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .preview__rating__stars-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    min-width: 340px;
    max-width: 340px; }
    .preview__rating__stars-wrapper__star {
      height: 30px;
      background-repeat: no-repeat;
      border: none;
      background-color: transparent; }
      .preview__rating__stars-wrapper__star:hover {
        background-color: transparent;
        cursor: default; }
      .preview__rating__stars-wrapper__star:hover:not([disabled]) {
        background-image: url("../assets/images/icons/star-full-violet.svg");
        cursor: pointer;
        background-color: transparent; }
      .preview__rating__stars-wrapper__star:hover:not([disabled]) ~ button {
        background-image: url("../assets/images/icons/star-full-violet.svg");
        cursor: pointer;
        background-repeat: no-repeat;
        background-color: transparent; }
  .preview__rating__numbers {
    display: flex;
    margin: auto;
    width: 65px;
    font-style: normal;
    font-weight: normal;
    font-size: 14px; }
    .preview__rating__numbers span {
      margin-right: 5px; }
    .preview__rating__numbers:hover {
      cursor: default; }
  .preview__rating__first {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    margin-right: 20px; }
  .preview__rating__close {
    padding: 0;
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px; }
    .preview__rating__close svg {
      width: 16px;
      height: 16px;
      vertical-align: middle; }
    .preview__rating__close:hover {
      background-color: transparent; }
      .preview__rating__close:hover svg > path {
        fill: #ef2f00; }

.preview__pb30 {
  padding-bottom: 30px; }

.add-file .MuiDialog-paperWidthSm {
  width: 700px;
  max-width: 700px; }

.add-file__no-files {
  margin: auto;
  font-size: 14px; }

.add-file__table .MuiTableCell-head {
  font-size: 0.875rem;
  color: #5b6887;
  padding: 6px 15px; }

.add-file__table .MuiTableCell-body {
  font-size: 0.8125rem;
  color: #848fa5;
  padding: 6px 15px; }

.add-file__action-panel {
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: center; }
  .add-file__action-panel .MuiButton-outlined {
    text-transform: none;
    border-radius: 13px;
    padding: 5px 10px;
    margin-left: 10px;
    border-color: #8065ec;
    font-size: 13px; }
  .add-file__action-panel__button {
    width: fit-content;
    height: 26px; }

.add-file__cell__name-cell {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-height: 30px;
  max-width: 300px; }

.add-file__cell__buttons-cell {
  min-width: 60px; }
  .add-file__cell__buttons-cell > button {
    height: 30px;
    width: 30px;
    padding: 7px; }

.add-file__dialog {
  padding: 10px 24px 24px !important; }
  .add-file__dialog__header {
    padding-right: 0 !important;
    margin: 0 24px !important;
    border-bottom: 1px solid #d5dce7; }
    .add-file__dialog__header > h6 {
      width: 100%;
      display: flex;
      justify-content: space-between; }
      .add-file__dialog__header > h6 > button {
        width: 38px; }
  .add-file__dialog__content {
    display: flex;
    width: 100%; }
    .add-file__dialog__content .image-dropzone {
      min-width: 250px;
      max-width: 250px;
      min-height: 250px;
      max-height: 250px;
      margin-top: 14px; }
      .add-file__dialog__content .image-dropzone .image-dropzone__upload-container {
        height: 250px; }
        .add-file__dialog__content .image-dropzone .image-dropzone__upload-container .image-dropzone__explanation {
          width: 235px;
          max-width: 235px; }
    .add-file__dialog__content__list-title {
      min-width: 396px;
      text-align: center;
      font-size: 18px; }
    .add-file__dialog__content__list {
      margin: 0;
      padding: 0;
      list-style: none;
      padding-left: 30px; }
      .add-file__dialog__content__list__loader {
        margin-top: -8px; }
        .add-file__dialog__content__list__loader > svg {
          height: 50px;
          width: 100%; }

.publish-button {
  background-origin: border-box;
  border-color: transparent;
  background-color: #8065ec;
  background-image: -webkit-linear-gradient(64.47deg, #8065ec 16.63%, #314ad7 83.37%);
  background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%);
  color: #ffffff;
  width: 105px !important; }

.publish-button:hover {
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(60, 72, 97, 0.3);
  background-color: #d5d5d5; }

.go-back-button {
  border-color: #d5dce7;
  background-color: transparent;
  color: #848fa5;
  width: 74px !important; }

.go-back-button:hover {
  background-color: rgba(132, 143, 165, 0.1); }

.go-to-meetup-preview {
  background-color: #8065ec;
  background-image: -webkit-linear-gradient(64.47deg, #8065ec 16.63%, #314ad7 83.37%);
  background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%);
  color: white !important;
  margin-left: 10px !important; }

.joined-to-meetup-preview {
  background-color: #9370db !important;
  color: white !important;
  margin-left: 10px !important; }

.go-to-meetup {
  position: absolute;
  top: 77%;
  left: 75%; }

.go-to-meetup button {
  padding: 3px !important;
  border-radius: 60px !important;
  width: 120px !important;
  color: white !important; }

.go-to-meetup-card {
  background-color: #8065ec;
  background-image: -webkit-linear-gradient(64.47deg, #8065ec 16.63%, #314ad7 83.37%);
  background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%); }

.joined-to-meetup-card {
  background-color: #9370db !important; }

.text-center {
  text-align: center; }

.meetup-create {
  width: 550px;
  text-align: center;
  margin: 0 auto;
  padding: 60px 0; }
  .meetup-create .MuiTabs-centered {
    border-bottom: 2px solid #e2e7ef; }
  .meetup-create__first-tab {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly; }
  .meetup-create__actions {
    padding: 20px 40px; }
  .meetup-create__page-heading {
    margin-top: 30px;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: center;
    color: #3c4861; }
  .meetup-create .speaker {
    margin-bottom: 25px;
    position: relative;
    z-index: 10; }
  .meetup-create .description {
    margin-top: 40px; }
  .meetup-create__tab-text {
    text-transform: none;
    font-size: 16px;
    margin-left: 15px; }
  .meetup-create__description {
    text-align: center;
    color: #5b6887;
    font-size: 0.875rem;
    line-height: 22px;
    margin-bottom: 16px; }
  .meetup-create .image-wrap {
    width: 48%;
    position: relative; }
  .meetup-create .default-image {
    width: 100%;
    filter: opacity(50%);
    transition: 1s;
    cursor: pointer;
    border-radius: 5px; }
  .meetup-create .default-image:hover {
    filter: opacity(100%); }
  .meetup-create .active-image {
    width: 100%;
    cursor: pointer;
    border-radius: 5px; }
  .meetup-create .images-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .meetup-create .image-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  .meetup-create .active-icon {
    display: inline;
    position: absolute;
    top: 5%;
    left: 5%; }
  .meetup-create .hide-icon {
    display: none; }
  .meetup-create .css-1f9w6yw-control {
    outline: none;
    box-shadow: none; }
  .meetup-create .MuiInputBase-root {
    padding: 0; }
  .meetup-create .MuiOutlinedInput-input {
    padding: 9.5px 16px; }
  .meetup-create .MuiOutlinedInput-inputMultiline {
    padding: 18.5px 16px; }
  .meetup-create .public-DraftEditor-content {
    min-height: 150px; }

.news-create {
  width: 550px;
  text-align: center;
  margin: 0 auto;
  padding: 60px 0; }
  .news-create .MuiTabs-centered {
    border-bottom: 2px solid #e2e7ef; }
  .news-create__first-tab {
    width: 100%;
    display: inline-flex;
    flex-direction: column;
    justify-content: space-evenly; }
  .news-create__page-heading {
    margin-top: 30px;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 28px;
    text-align: center;
    color: #3c4861; }
  .news-create__description {
    min-height: 300px;
    margin-bottom: 15px;
    text-align: center;
    color: #5b6887;
    font-size: 0.875rem; }
  .news-create .image-wrap {
    width: 48%;
    position: relative; }
  .news-create .default-image {
    width: 100%;
    filter: opacity(50%);
    transition: 1s;
    cursor: pointer;
    border-radius: 5px; }
  .news-create .default-image:hover {
    filter: opacity(100%); }
  .news-create__actions {
    padding: 20px 40px; }
  .news-create .active-image {
    width: 100%;
    cursor: pointer;
    border-radius: 5px; }
  .news-create .images-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  .news-create .image-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center; }
  .news-create .active-icon {
    display: inline;
    position: absolute;
    top: 5%;
    left: 5%; }
  .news-create .hide-icon {
    display: none; }
  .news-create .css-1f9w6yw-control {
    outline: none;
    box-shadow: none; }
  .news-create .public-DraftEditor-content {
    min-height: 150px; }

.news-preview {
  width: 550px;
  text-align: center;
  margin: 0 auto;
  padding: 60px 0; }
  .news-preview__img-wrapper {
    font-size: 0;
    display: flex;
    justify-content: center; }
  .news-preview__likes {
    min-height: 70px;
    min-width: 70px;
    max-width: 50px;
    border-radius: 35px;
    background-color: #ffffff;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    display: flex;
    position: relative;
    justify-content: space-evenly;
    align-items: center;
    top: -40px;
    left: 450px; }
    .news-preview__likes__count {
      color: #aab6ca;
      font-size: 15px; }
    .news-preview__likes__count.hidden {
      display: none; }
  .news-preview__header {
    margin: -70px 0 0;
    padding: 30px 25px 20px;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.33px;
    color: #3c4861;
    overflow-wrap: break-word; }
  .news-preview__description {
    padding: 24px !important; }

.meetup-edit__dropzone-wrapper {
  display: flex;
  justify-content: space-between; }

.meetup-edit__container {
  width: 46%;
  height: 175px;
  position: relative; }

.meetup-edit__preview__topic-img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  display: block;
  width: 100%;
  height: 175px;
  object-fit: cover;
  border-radius: 5px; }

.meetup-edit__button-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 175px;
  text-align: center;
  background: rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  opacity: 0; }

.meetup-edit__button-container svg {
  position: relative;
  top: calc(50% - 25px); }

.meetup-edit__container:hover .meetup-edit__button-container {
  opacity: 1; }

.meetup-edit .speaker {
  margin-bottom: 25px;
  position: relative;
  z-index: 10; }

.meetup-edit .public-DraftEditor-content {
  min-height: 150px; }

.page-content {
  width: 550px;
  margin: 0 auto;
  padding: 60px 0; }

.page-heading {
  padding-bottom: 50px;
  margin: 0;
  font-weight: 700;
  font-size: 1.5rem;
  line-height: 28px;
  text-align: center;
  color: #3c4861; }

.App .form__field {
  background-color: rgba(244, 246, 252, 0.4); }
  .App .form__field:last-child {
    margin-bottom: 0; }
  .App .form__field .form__field-label {
    color: #aab6ca;
    font-size: 0.875rem;
    z-index: 0;
    transform: translate(14px, 13px) scale(1); }
    .App .form__field .form__field-label.form__field-label_shrink {
      transform: translate(13px, -4px) scale(0.75);
      background-color: #ffffff; }
    .App .form__field .form__field-label.form__field-label_focused {
      color: #8065ec; }
    .App .form__field .form__field-label.form__field-label_disabled {
      color: #aab6ca; }
  .App .form__field .form__field-input-wrapper {
    padding: 0; }
    .App .form__field .form__field-input-wrapper fieldset {
      border-color: #d5dce7; }
    .App .form__field .form__field-input-wrapper.form__field-input-wrapper_disabled fieldset {
      border-color: #d5dce7; }
    .App .form__field .form__field-input-wrapper.form__field-input-wrapper_focused fieldset {
      border-width: 1px;
      border-color: #8065ec; }
  .App .form__field .form__field-input {
    box-sizing: border-box;
    padding: 10.5px 14px;
    min-height: 40px;
    max-height: 40px;
    font-size: 0.875rem;
    color: #5b6887; }
  .App .form__field .form__field-input-wrapper:hover:not(.form__field-input-wrapper_disabled):not(.form__field-input-wrapper_focused):not(.form__field-input-wrapper_error) fieldset {
    border-color: #8065ec; }
  .App .form__field__counter {
    display: flex;
    justify-content: flex-end;
    font-size: 0.625rem;
    color: #aab6ca;
    opacity: 0; }
  .App .form__field__show {
    opacity: 1; }
  .App .form__field__hide {
    opacity: 0; }
  .App .form__field__invalid {
    color: #ff380c; }

.App .button {
  box-sizing: border-box;
  margin: 0;
  padding: 11px 15px;
  border-radius: 5px;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 16px;
  width: 152px;
  text-transform: none;
  transition-property: color, background-color, box-shadow;
  transition-duration: 0.4s; }
  .App .button__label {
    position: relative;
    z-index: 0; }
  .App .button__ripple {
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px); }
  .App .button__ripple-visible {
    opacity: 1; }
  .App .button__no-outline {
    outline: none; }
  .App .button_link {
    text-align: center; }
    .App .button_link:hover {
      text-decoration: none; }
  .App .button_small {
    width: 128px; }
  .App .button_wide {
    width: 168px; }
  .App .button_contained-violet {
    background-origin: border-box;
    border-color: transparent;
    background-color: #8065ec;
    background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%);
    color: white; }
    .App .button_contained-violet:hover {
      box-shadow: 0 4px 8px rgba(60, 72, 97, 0.3); }
      .App .button_contained-violet:hover .button__ripple-child {
        background-color: #2a40bf; }
    .App .button_contained-violet:active {
      box-shadow: 0 4px 8px rgba(60, 72, 97, 0.2); }
  .App .button_outlined-grey {
    border-color: #d5dce7;
    background-color: transparent;
    color: #d5dce7; }
    .App .button_outlined-grey:hover {
      background-color: rgba(132, 143, 165, 0.1); }
    .App .button_outlined-grey:active {
      border-color: #c2cbda; }
      .App .button_outlined-grey:active .button__ripple-child {
        background-color: rgba(132, 143, 165, 0.2); }
  .App .button_outlined-violet {
    border-color: #8065ec;
    background-color: transparent;
    color: #8065ec; }
    .App .button_outlined-violet:hover {
      background-color: rgba(128, 101, 236, 0.2); }
    .App .button_outlined-violet:active .button__ripple-child {
      background-color: rgba(128, 101, 236, 0.2); }
  .App .button.add-file-button {
    width: 123px;
    padding-left: 2px;
    padding-right: 2px; }
  .App .button.delete-button {
    width: 65px; }
  .App .button.xs_button {
    width: 80px; }
  .App .button.s_button {
    width: 95px; }
  .App .button.m_button {
    width: 110px; }
  .App .button.l_button {
    width: 140px; }
  .App .button.xl_button {
    width: 191px !important; }
  .App .button.draft-button {
    width: 120px; }
  .App .button.edit-button {
    width: 115px; }
  .App .button.admin-button {
    width: 190px; }
  .App .button.like-button {
    background-color: #8065ec;
    background-image: -webkit-linear-gradient(64.47deg, #8065ec 16.63%, #314ad7 83.37%);
    background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%); }
  .App .button.dislike-button {
    background-color: #9370db;
    color: #ffffff; }
  .App .button.button_disabled {
    border-color: #c2cbda;
    background: #c2cbda;
    color: #ffffff; }
  .App .button.focus_visible {
    border: none;
    outline: -webkit-focus-ring-color auto 1px; }
    .App .button.focus_visible .button__ripple-child {
      display: none; }

@keyframes mui-ripple-enter {
  0% {
    transform: scale(0);
    opacity: 0.1; }
  100% {
    transform: scale(1);
    opacity: 1; } }

.App .card {
  width: 100%;
  margin: 0 0 24px;
  background: white;
  box-shadow: 0 4px 7px rgba(215, 221, 232, 0.398055);
  border-radius: 5px;
  overflow: visible;
  box-sizing: border-box; }
  .App .card_form {
    padding: 40px;
    box-shadow: 0 6px 12px #e2e7ef; }
  .App .card_memo-form {
    padding: 12px 40px 40px; }
  .App .card_controls {
    margin-bottom: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%; }
  .App .card > div {
    padding: 0; }
  .App .card_no-padding {
    padding: 0; }
  .App .card_with-padding-bottom {
    padding-bottom: 30px; }
  .App .card_time-n-place {
    list-style: none; }
  .App .card_speaker {
    display: flex;
    align-items: center; }
  .App .card__actions {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .App .card__actions .button:first-child:not(:last-child) {
      margin-right: auto; }
  .App .card__heading {
    margin: 0 0 0 5px;
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 24px;
    color: #848fa5; }

.labels {
  display: flex;
  justify-content: space-between; }
  .labels .input-label {
    min-width: 225px; }

.date-pickers {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between; }
  .date-pickers__clear-button {
    width: 25px;
    height: 25px; }
  .date-pickers__button-container {
    display: flex;
    padding-right: 10px;
    align-items: flex-end; }
  .date-pickers__start-date, .date-pickers__end-date {
    width: 225px; }
  .date-pickers__end-date.hidden {
    visibility: hidden; }
  .date-pickers .MuiInputBase-root {
    min-width: 200px; }
  .date-pickers .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: #8065ec; }
  .date-pickers .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important; }
  .date-pickers .MuiInputBase-input {
    color: #5b6887 !important;
    font-size: 0.875rem;
    padding: 10px; }
  .date-pickers .MuiOutlinedInput-adornedEnd {
    padding-right: 0; }
  .date-pickers .MuiIconButton-root {
    padding: 5px; }
  .date-pickers .MuiIconButton-label {
    width: auto; }
  .date-pickers .MuiInputLabel-formControl {
    color: #848fa5;
    font-size: 0.875rem; }
  .date-pickers .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(12px, 14px) scale(1); }
  .date-pickers .MuiOutlinedInput-adornedEnd {
    background-color: rgba(244, 246, 252, 0.4); }
  .date-pickers .MuiFormLabel-root.Mui-focused {
    color: #8065ec; }
  .date-pickers .iconCalendar {
    color: #8065ec;
    transition-property: color;
    transition-duration: 0.3s; }
  .date-pickers .react-datepicker {
    border-color: #d5dce7;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px; }
    .date-pickers .react-datepicker__day--disabled {
      color: #5b6887 !important;
      opacity: 0.2 !important; }
    .date-pickers .react-datepicker__triangle {
      left: 185px; }
    .date-pickers .react-datepicker__header {
      background-color: #ffffff !important;
      border-bottom: 1px solid #d5dce7;
      margin: 8px; }
    .date-pickers .react-datepicker__day-names {
      margin-top: 16px;
      border-top: 1px solid #d5dce7; }
    .date-pickers .react-datepicker__header--time {
      padding-bottom: 16px;
      margin-bottom: 0; }
    .date-pickers .react-datepicker__month-container {
      border-right: 1px solid #d5dce7;
      height: 100%; }
    .date-pickers .react-datepicker .react-datepicker__month {
      margin: 0; }
    .date-pickers .react-datepicker__time-container {
      border-left: none;
      width: 150px; }
    .date-pickers .react-datepicker__time .date-pickers .react-datepicker__time-box {
      width: 100%;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center; }
    .date-pickers .react-datepicker__time-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px !important; }
    .date-pickers .react-datepicker__navigation {
      top: 17px !important; }
    .date-pickers .react-datepicker__navigation--next--with-time {
      right: 160px !important; }
    .date-pickers .react-datepicker__day {
      color: #5b6887 !important; }
    .date-pickers .react-datepicker__time {
      color: #5b6887 !important; }
    .date-pickers .react-datepicker__day--selected {
      background: linear-gradient(166.72deg, #8065ec -2.22%, #314ad7 103.24%);
      color: #ffffff !important; }
    .date-pickers .react-datepicker__day--keyboard-selected {
      background: linear-gradient(166.72deg, #8065ec -2.22%, #314ad7 103.24%);
      color: #ffffff !important; }
    .date-pickers .react-datepicker__day--outside-month {
      color: #5b6887 !important;
      opacity: 0.2 !important; }
  .date-pickers .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: auto !important; }
  .date-pickers .MuiInputBase-input {
    color: #5b6887; }
  .date-pickers li.react-datepicker__time-list-item--selected {
    background: linear-gradient(166.72deg, #8065ec -2.22%, #314ad7 103.24%);
    color: #ffffff !important; }
  .date-pickers li.react-datepicker__time-list-item--disabled {
    display: none; }
  .date-pickers .react-datepicker-popper {
    z-index: 100 !important; }

.image-loaded__heading {
  margin: 0 0 5px;
  color: #aab6ca;
  font-size: 0.8125rem; }

.image-loaded__container {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px dashed #d5d5d5;
  border-radius: 5px; }
  .image-loaded__container--loaded {
    padding: 0;
    border: none;
    min-height: 264px; }

.image-loaded__image {
  margin-right: 10px; }
  .image-loaded__image--loaded {
    width: 100%;
    height: 264px;
    margin-right: 0;
    object-fit: cover;
    border-radius: 5px; }

.image-loaded__info {
  margin-right: 10px;
  max-width: calc(100% - 20px - 32px - 24px); }
  .image-loaded__info--loaded {
    display: none; }

.image-loaded__image-title {
  margin: 0;
  color: #3c4861;
  font-size: 0.875rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.image-loaded__image-size {
  margin: 0;
  color: #aab6ca;
  font-size: 0.625rem; }

.image-loaded__icon {
  color: #6c757d;
  cursor: pointer; }
  .image-loaded__icon--loaded {
    position: absolute;
    right: 0;
    top: -25px; }

/*!
 * Load Awesome v1.1.0 (http://github.danielcardoso.net/load-awesome/)
 * Copyright 2015 Daniel Cardoso <@DanielCardoso>
 * Licensed under MIT
 */
.la-pacman,
.la-pacman > div {
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

.la-pacman {
  display: block;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0;
  color: #8065ec; }

.la-pacman.la-dark {
  color: #333; }

.la-pacman > div {
  display: inline-block;
  float: none;
  background-color: currentColor;
  border: 0 solid currentColor; }

.la-pacman {
  width: 32px;
  height: 32px; }

.la-pacman > div:nth-child(1),
.la-pacman > div:nth-child(2) {
  width: 0;
  height: 0;
  background: transparent;
  border-style: solid;
  border-width: 16px;
  border-right-color: transparent;
  border-radius: 100%;
  -webkit-animation: pacman-rotate-half-up 0.5s 0s infinite;
  -moz-animation: pacman-rotate-half-up 0.5s 0s infinite;
  -o-animation: pacman-rotate-half-up 0.5s 0s infinite;
  animation: pacman-rotate-half-up 0.5s 0s infinite; }

.la-pacman > div:nth-child(2) {
  margin-top: -32px;
  -webkit-animation-name: pacman-rotate-half-down;
  -moz-animation-name: pacman-rotate-half-down;
  -o-animation-name: pacman-rotate-half-down;
  animation-name: pacman-rotate-half-down; }

.la-pacman > div:nth-child(3),
.la-pacman > div:nth-child(4),
.la-pacman > div:nth-child(5),
.la-pacman > div:nth-child(6) {
  position: absolute;
  top: 50%;
  left: 200%;
  width: 8px;
  height: 8px;
  border-radius: 100%;
  opacity: 0;
  -webkit-animation: pacman-balls 2s 0s infinite linear;
  -moz-animation: pacman-balls 2s 0s infinite linear;
  -o-animation: pacman-balls 2s 0s infinite linear;
  animation: pacman-balls 2s 0s infinite linear; }

.la-pacman > div:nth-child(3) {
  -webkit-animation-delay: -1.44s;
  -moz-animation-delay: -1.44s;
  -o-animation-delay: -1.44s;
  animation-delay: -1.44s; }

.la-pacman > div:nth-child(4) {
  -webkit-animation-delay: -1.94s;
  -moz-animation-delay: -1.94s;
  -o-animation-delay: -1.94s;
  animation-delay: -1.94s; }

.la-pacman > div:nth-child(5) {
  -webkit-animation-delay: -2.44s;
  -moz-animation-delay: -2.44s;
  -o-animation-delay: -2.44s;
  animation-delay: -2.44s; }

.la-pacman > div:nth-child(6) {
  -webkit-animation-delay: -2.94s;
  -moz-animation-delay: -2.94s;
  -o-animation-delay: -2.94s;
  animation-delay: -2.94s; }

.la-pacman.la-sm {
  width: 16px;
  height: 16px; }

.la-pacman.la-sm > div:nth-child(1),
.la-pacman.la-sm > div:nth-child(2) {
  border-width: 8px; }

.la-pacman.la-sm > div:nth-child(2) {
  margin-top: -16px; }

.la-pacman.la-sm > div:nth-child(3),
.la-pacman.la-sm > div:nth-child(4),
.la-pacman.la-sm > div:nth-child(5),
.la-pacman.la-sm > div:nth-child(6) {
  width: 4px;
  height: 4px; }

.la-pacman.la-2x {
  width: 64px;
  height: 64px; }

.la-pacman.la-2x > div:nth-child(1),
.la-pacman.la-2x > div:nth-child(2) {
  border-width: 32px; }

.la-pacman.la-2x > div:nth-child(2) {
  margin-top: -64px; }

.la-pacman.la-2x > div:nth-child(3),
.la-pacman.la-2x > div:nth-child(4),
.la-pacman.la-2x > div:nth-child(5),
.la-pacman.la-2x > div:nth-child(6) {
  width: 16px;
  height: 16px; }

.la-pacman.la-3x {
  width: 96px;
  height: 96px;
  margin-top: 50px; }

.la-pacman.la-3x > div:nth-child(1),
.la-pacman.la-3x > div:nth-child(2) {
  border-width: 48px; }

.la-pacman.la-3x > div:nth-child(2) {
  margin-top: -96px; }

.la-pacman.la-3x > div:nth-child(3),
.la-pacman.la-3x > div:nth-child(4),
.la-pacman.la-3x > div:nth-child(5),
.la-pacman.la-3x > div:nth-child(6) {
  width: 24px;
  height: 24px; }

/*
  * Animations
  */
@-webkit-keyframes pacman-rotate-half-up {
  0%,
  100% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  50% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-moz-keyframes pacman-rotate-half-up {
  0%,
  100% {
    -moz-transform: rotate(270deg);
    transform: rotate(270deg); }
  50% {
    -moz-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-o-keyframes pacman-rotate-half-up {
  0%,
  100% {
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  50% {
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes pacman-rotate-half-up {
  0%,
  100% {
    -webkit-transform: rotate(270deg);
    -moz-transform: rotate(270deg);
    -o-transform: rotate(270deg);
    transform: rotate(270deg); }
  50% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@-webkit-keyframes pacman-rotate-half-down {
  0%,
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-moz-keyframes pacman-rotate-half-down {
  0%,
  100% {
    -moz-transform: rotate(90deg);
    transform: rotate(90deg); }
  50% {
    -moz-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-o-keyframes pacman-rotate-half-down {
  0%,
  100% {
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  50% {
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@keyframes pacman-rotate-half-down {
  0%,
  100% {
    -webkit-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -o-transform: rotate(90deg);
    transform: rotate(90deg); }
  50% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); } }

@-webkit-keyframes pacman-balls {
  0% {
    left: 200%;
    opacity: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); }
  5% {
    opacity: 0.5; }
  66% {
    opacity: 1; }
  67% {
    opacity: 0; }
  100% {
    left: 0;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%); } }

@-moz-keyframes pacman-balls {
  0% {
    left: 200%;
    opacity: 0;
    -moz-transform: translateY(-50%);
    transform: translateY(-50%); }
  5% {
    opacity: 0.5; }
  66% {
    opacity: 1; }
  67% {
    opacity: 0; }
  100% {
    left: 0;
    -moz-transform: translateY(-50%);
    transform: translateY(-50%); } }

@-o-keyframes pacman-balls {
  0% {
    left: 200%;
    opacity: 0;
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  5% {
    opacity: 0.5; }
  66% {
    opacity: 1; }
  67% {
    opacity: 0; }
  100% {
    left: 0;
    -o-transform: translateY(-50%);
    transform: translateY(-50%); } }

@keyframes pacman-balls {
  0% {
    left: 200%;
    opacity: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); }
  5% {
    opacity: 0.5; }
  66% {
    opacity: 1; }
  67% {
    opacity: 0; }
  100% {
    left: 0;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%); } }

.page-not-found__wrapper {
  width: 550px;
  margin: 0 auto;
  padding: 14% 0;
  text-align: center; }

.notification-container {
  top: 60px;
  min-width: 560px;
  font-size: 0.875rem;
  overflow: hidden; }
  .notification-container .notification-success {
    background: linear-gradient(to right, #009a73 56px, #2bbc7e 56px, #2bbc7e); }
    .notification-container .notification-success::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 19px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      margin: 0;
      background-image: url("../assets/images/icons/icon-success.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; }
  .notification-container .notification-error {
    background: linear-gradient(to right, #ef2f00 56px, #ff380c 56px, #ff380c); }
    .notification-container .notification-error::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 19px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      margin: 0;
      background-image: url("../assets/images/icons/icon-error.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; }
    .notification-container .notification-error::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 19px;
      transform: translateY(-50%);
      width: 14px;
      height: 14px;
      margin: 0;
      background-image: url("../assets/images/icons/icon-close.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; }
  .notification-container .notification-info {
    background: linear-gradient(to right, #314ad7 56px, #7989e7 56px, #7989e7); }
    .notification-container .notification-info::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 19px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      margin: 0;
      background-image: url("../assets/images/icons/icon-info.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; }
  .notification-container .notification-warning {
    background: linear-gradient(to right, #fb9700 56px, #ffc121 56px, #ffc121); }
    .notification-container .notification-warning::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 19px;
      transform: translateY(-50%);
      width: 20px;
      height: 20px;
      margin: 0;
      background-image: url("../assets/images/icons/icon-warning.svg");
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain; }
  .notification-container .notification {
    padding: 19px 15px 16px 58px;
    box-shadow: none;
    border-radius: 4px; }
    .notification-container .notification .title {
      margin-right: 5px; }
  .notification-container .notification-message {
    display: flex;
    padding-left: 18px; }

.news-card {
  background-color: #ffffff;
  border-radius: 6px;
  box-shadow: 0 4px 9px rgba(194, 203, 218, 0.25);
  margin-bottom: 16px;
  color: #6c757d;
  display: flex;
  flex-direction: row;
  margin-left: 8px; }
  .news-card__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 136px;
    padding-top: 10px; }
    .news-card__header__subject {
      margin: 0 5px 0 20px;
      font-weight: 500;
      font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
      font-size: 1.125rem;
      color: #3c4861;
      line-height: 20px;
      letter-spacing: -0.3px;
      overflow-wrap: break-word;
      max-height: 40px;
      overflow: hidden; }
    .news-card__header__buttons {
      display: flex;
      align-items: center;
      margin: 0; }
      .news-card__header__buttons button {
        width: min-content;
        padding: 10px; }
  .news-card__body {
    padding-left: 140px; }
  .news-card__footer {
    padding-left: 140px;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .news-card__footer__date {
      padding: 15px 20px;
      color: #aab6ca;
      font-size: 0.875rem; }
    .news-card__footer__count {
      padding-right: 10px;
      color: #aab6ca;
      font-size: 0.875rem; }
    .news-card__footer__count.hidden {
      display: none; }
    .news-card__footer__likes {
      display: flex;
      align-items: center; }
  .news-card__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 160px;
    border: 1px solid transparent; }
    .news-card__content__excerpt {
      display: flex;
      padding: 0 20px 15px 20px;
      font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
      font-size: 0.875rem;
      line-height: 21px;
      color: #848fa5;
      max-height: calc(0.875rem * 2);
      overflow: hidden;
      margin-bottom: 0;
      margin-top: 10px; }
  .news-card__topic-img-wrapper {
    position: absolute;
    left: -8px;
    top: -8px;
    width: 144px;
    height: 144px;
    border-radius: 5px;
    background-color: #f1eee9;
    box-shadow: 3px 5px 8px rgba(105, 112, 127, 0.34); }
    .news-card__topic-img-wrapper img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 5px; }

@font-face {
  font-family: 'Rubik';
  src: url("../assets/fonts/Rubik-Regular.ttf") format("truetype"), url("../assets/fonts/Rubik-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'Rubik';
  src: url("../assets/fonts/Rubik-Medium.ttf") format("truetype"), url("../assets/fonts/Rubik-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal; }

@font-face {
  font-family: 'Roboto';
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

.DraftEditor-root {
  position: relative;
  padding: 0;
  font-size: 14px;
  border: none;
  background: inherit; }
  .DraftEditor-root .public-DraftEditorPlaceholder-inner {
    position: absolute;
    margin-top: 2px;
    color: #aab6ca; }

.DraftEditor-editor {
  cursor: text;
  margin-top: 10px;
  font-size: 16px; }
  .DraftEditor-editor .public-DraftEditorPlaceholder-root,
  .DraftEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px; }
  .DraftEditor-editor .public-DraftEditor-content {
    min-height: 100px; }
  .DraftEditor-editor .public-DraftStyleDefault-pre {
    padding: 20px;
    font-size: 16px; }

.DraftEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none; }

.DraftEditor-editorContainer {
  position: relative;
  z-index: 1;
  font-size: 1rem;
  text-align: left; }
  .DraftEditor-editorContainer .public-DraftStyleDefault-block {
    margin-bottom: 10px; }
  .DraftEditor-editorContainer blockquote {
    margin: 16px 0;
    padding: 10px 20px;
    font-style: italic;
    border-left: 5px solid #d5dce7;
    color: #848fa5; }

.DraftEditor-controls {
  margin-bottom: 5px;
  font-size: 14px;
  user-select: none; }

.DraftEditor-styleButton {
  display: inline-block;
  margin-right: 16px;
  padding: 2px 0;
  cursor: pointer; }

.DraftEditor__container.edit {
  background-color: rgba(244, 246, 252, 0.4);
  border: 1px solid #d5dce7;
  border-radius: 4px; }

.DraftEditor__container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: #ffffff;
  pointer-events: auto;
  outline: none;
  word-break: break-word; }
  .DraftEditor__container button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    margin: 0 2px;
    font-size: 0.8em;
    border-radius: 0;
    border: none;
    background: none;
    color: #848fa5; }
    .DraftEditor__container button:hover, .DraftEditor__container button:active {
      color: #ffffff;
      background-color: #8065ec; }
    .DraftEditor__container button b {
      display: none; }
    .DraftEditor__container button svg {
      font-size: 1.125rem; }
  .DraftEditor__container .selected {
    color: #3c4861; }
  .DraftEditor__container:hover, .DraftEditor__container:active, .DraftEditor__container:focus {
    border-width: 1px;
    border-color: #8065ec; }

.DraftEditor__editor.edit {
  max-height: 400px;
  margin: 0;
  overflow-y: auto;
  width: 100%; }
  .DraftEditor__editor.edit .DraftEditor-root {
    padding: 10px 25px 5px 13px; }

.DraftEditor__toolbar {
  display: none; }

.DraftEditor__toolbar.edit {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  border-bottom: 1px solid #d5dce7; }

.DraftEditor__description-counter {
  display: flex;
  justify-content: flex-end;
  font-size: 0.625rem;
  color: #aab6ca;
  opacity: 0;
  margin-bottom: 15px; }

.DraftEditor__counter__show {
  opacity: 1; }

.change-link {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 2px; }
  .change-link button {
    width: 100%;
    margin-left: 0 !important; }
    .change-link button:disabled {
      cursor: not-allowed;
      opacity: 0.5; }

.inline-link {
  color: #2a40bf;
  text-decoration: none; }
  .inline-link:hover, .inline-link:active {
    text-decoration: underline; }

.divider {
  display: flex;
  border-right: 1px solid #d5dce7;
  min-height: 20px;
  margin: 0 8px 0 8px; }

.container-height {
  min-height: 12em; }

.news-list {
  justify-content: left; }
  .news-list__list {
    margin: 0;
    padding: 0;
    list-style: none; }
  .news-list__wrapper {
    width: 550px;
    margin: 0 auto;
    padding: 60px 0; }
  .news-list__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-bottom: 30px; }
    .news-list__header__heading {
      margin-bottom: 0;
      font-weight: 700;
      font-size: 1.5rem;
      line-height: 28px;
      color: #3c4861; }
    .news-list__header .button.button_outlined-violet {
      width: max-content; }

.InputPopover__root {
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  width: 350px;
  padding: 4px;
  box-sizing: border-box;
  background: linear-gradient(to bottom, #f4f6fc 0%, #f6f7f8 100%);
  border: 1px solid #848fa5;
  border-radius: 2px;
  z-index: 5; }
  .InputPopover__root::before {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: -10px;
    left: 10px;
    border: 5px solid transparent;
    border-bottom-color: #848fa5; }
  .InputPopover__root::after {
    content: '';
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    top: -9px;
    left: 10px;
    border: 5px solid transparent;
    border-bottom-color: #f4f6fc; }

.InputPopover__inner {
  display: flex;
  align-items: baseline; }
  .InputPopover__inner label {
    min-width: 60px;
    margin: 5px 5px; }

.InputPopover__input {
  display: inline-block;
  flex: 1 0 auto;
  height: 30px;
  padding: 2px 6px;
  margin: 5px 5px;
  font-size: 14px;
  line-height: 24px;
  background: none #ffffff;
  border: 1px solid #848fa5;
  border-radius: 2px;
  box-sizing: border-box; }
  .InputPopover__input:focus, .InputPopover__input:active {
    outline: none; }

.InputPopover__buttonGroup {
  display: flex;
  justify-content: space-between;
  margin: 10px 5px; }
  .InputPopover__buttonGroup button {
    display: inline-block;
    height: 30px;
    width: 142px;
    margin: 0;
    padding: 3px;
    font-size: inherit;
    font-family: inherit;
    line-height: 22px;
    text-decoration: none;
    white-space: nowrap;
    color: #333;
    box-sizing: border-box;
    background: linear-gradient(to bottom, #f4f6fc 0%, #f6f7f8 100%);
    border: 1px solid #848fa5;
    border-radius: 2px;
    cursor: pointer; }
    .InputPopover__buttonGroup button:hover, .InputPopover__buttonGroup button:active {
      color: #ffffff;
      background: #314ad7; }

.ButtonGroup {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 0 4px;
  white-space: nowrap; }
  .ButtonGroup__root {
    white-space: nowrap; }

.ButtonWrap__root {
  display: inline-block;
  position: relative;
  z-index: 10;
  margin-right: -1px; }

.image-dropzone {
  width: 100%;
  margin-top: 16px;
  border: 1px dashed #d5dce7;
  border-radius: 5px;
  background-color: rgba(244, 246, 252, 0.2); }
  .image-dropzone__upload-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 15px;
    box-sizing: border-box;
    color: #5b6887;
    background-color: rgba(244, 246, 252, 0.2);
    outline: none;
    cursor: pointer; }
  .image-dropzone__upload-image {
    width: 41px;
    height: 28px;
    margin-top: 10px; }
  .image-dropzone__explanation {
    max-width: 200px;
    margin-bottom: 25px;
    font-size: 0.8125rem; }
    .image-dropzone__explanation span {
      color: #2a40bf; }
  .image-dropzone__warning {
    color: #aab6ca;
    font-size: 0.625rem;
    margin: 0 0 3px 0; }
  .image-dropzone--edit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46%;
    margin-top: 0;
    padding: 5px 10px;
    text-align: center; }
    .image-dropzone--edit .image-dropzone__upload-container {
      padding: 0; }

.notification-list__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0.25px;
  font-size: 0.75rem;
  padding: 0 10px;
  color: #3c4861;
  border-bottom: 1px solid #d5dce7; }
  .notification-list__header__new {
    color: #848fa5; }

@-moz-document url-prefix() {
  .notification-list__header__new {
    margin-right: 20px; } }

.notification-list__list {
  margin: 0;
  padding: 0 15px 10px; }

.notification-list__icon {
  width: 40px;
  padding: 5px; }

.notification-list__read {
  opacity: 0.45; }

.notification-list__card {
  display: flex;
  min-width: 400px;
  background-color: #f4f6fc;
  padding: 10px 0;
  height: 40px;
  border-bottom: 1px solid #d5dce7; }
  .notification-list__card__main-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-left: 10px; }
  .notification-list__card__date {
    color: #848fa5;
    font-size: 0.75rem;
    padding-right: 5px; }

@-moz-document url-prefix() {
  .notification-list__card__date {
    white-space: nowrap;
    padding-right: 20px; } }
  .notification-list__card__text-block {
    cursor: pointer;
    font-size: 0.75rem;
    display: block;
    line-height: 18px;
    max-width: 300px; }
    .notification-list__card__text-block__header {
      font-weight: bold; }
    .notification-list__card__text-block__subject {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .notification-list__card__text-block__no-click {
      cursor: default; }

.file-card {
  display: flex;
  flex-direction: row;
  font-size: 0.875rem;
  line-height: 30px; }
  .file-card__container {
    display: flex;
    align-items: center; }
    .file-card__container > button {
      display: flex;
      padding: 5px;
      width: 28px; }
  .file-card__title {
    max-width: 270px;
    min-width: 270px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding-right: 10px; }
  .file-card__size {
    display: flex;
    width: 55px;
    padding-right: 10px; }

.admin-panel__list {
  margin: 0;
  padding: 0;
  list-style: none;
  border-left: 2px solid #c2cbda;
  padding-left: 45px; }

.admin-panel__list-item {
  padding: 10px 0; }

.admin-panel__button {
  width: 190px; }

.admin-panel__wrapper {
  width: 550px;
  margin: 0 auto;
  padding: 60px 0; }

.admin-panel__header {
  margin-bottom: 30px;
  width: 100%;
  display: flex;
  justify-content: center; }
  .admin-panel__header__heading {
    margin-bottom: 0;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 28px;
    color: #3c4861; }

.admin-panel__body {
  padding-left: 140px; }

.memo-preview__topic-name {
  margin: 0;
  padding-bottom: 15px;
  font-style: normal;
  font-size: 1.375rem;
  font-weight: 600;
  letter-spacing: -0.33px;
  line-height: 30px;
  overflow-wrap: break-word; }

.memo-preview__item-wrap:not(:last-child) {
  padding-bottom: 30px !important; }

.memo-preview__item-wrap b {
  font-weight: 600; }

.memo-preview__item-wrap br {
  display: none; }

.memo-preview__description p {
  margin: 4px 0;
  padding: 0;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-style: normal;
  font-size: 1rem;
  letter-spacing: -0.33px;
  line-height: 22px; }

.img-arrow {
  height: 19px;
  width: 30px; }

.memo-main-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center; }

.add-memo-button {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
  cursor: pointer; }

.add-memo-button:before {
  content: '';
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  width: 35px;
  font-size: 30px;
  color: black;
  border: 2px solid black;
  border-radius: 50%; }

.plus:before {
  content: '+'; }
