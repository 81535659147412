.card {
  width: 100%;
  margin: 0 0 24px;
  padding: 20px 24px;
  background: $white;
  box-shadow: 0 4px 7px rgba(215, 221, 232, 0.398055);
  border-radius: $border-radius;
  overflow: visible;
  box-sizing: border-box;

  &__heading {
    margin: 0 0 0 5px;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 24px;
    color: $gray-02;
  }

  &__no-padding {
    padding: 0;
  }
  &__10-bottom-margin {
    margin-bottom: 10px !important;
  }

  &__time-n-place {
    width: 100%;
    margin: 0 0 15px;
    padding: 20px 25px;
    background: $white;
    box-shadow: 0 4px 7px rgba(215, 221, 232, 0.398055);
    border-radius: $border-radius;
    overflow: visible;
    box-sizing: border-box;
  }

  &__speaker {
    display: flex;
    align-items: center;
  }

  &__rate {
    display: flex;
    align-items: center;
    padding: 14px 22px;
  }

  &__description {
    margin: 0;
    font-family: Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-size: 1rem;
    line-height: 30px;
    letter-spacing: -0.33px;
    color: $darkgray;
    white-space: pre-line;
  }

  &__actions {
    display: flex;
    align-items: center;
  }
  &__main-actions {
    padding-left: 10px !important;
  }

  &__controls {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  &__participants {
    padding: 17px;

    span:not(:first-child) .sb-avatar.sb-avatar--text {
      padding-left: 10px;
    }
  }
}

.full-rated-star {
  background-image: url('../assets/images/icons/star-full-violet.svg');
}

.empty-rated-star {
  background-image: url('../assets/images/icons/star-empty-violet.svg');
}

.full-unrated-star {
  background-image: url('../assets/images/icons/star-full-grey.svg');
}

.empty-unrated-star {
  background-image: url('../assets/images/icons/star-empty-grey.svg');
}

.preview {
  &__topic-name {
    margin: 0;
    padding: 30px 25px 0;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    letter-spacing: -0.33px;
    color: $darkgray;
    overflow-wrap: break-word;
  }

  &__topic-img-wrapper {
    font-size: 0;
    display: flex;
    justify-content: center;
  }

  &__topic-img {
    width: 100%;
    height: 308px;
    object-fit: cover;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }

  &__time-n-place-item {
    display: flex;
    list-style-type: none;
    margin-bottom: 17px;
    font-style: normal;
    font-weight: 600;
    font-size: 1rem;
    letter-spacing: -0.2px;
    color: $darkgray;
    overflow-wrap: break-word;

    &__place {
      width: calc(#{$app-content-width} - 100px);
    }
  }

  &__time-n-place-item.red {
    color: $red;
  }

  &__time-n-place-time-separator {
    padding: 0 5px;
  }

  &__time-n-place-item:last-child {
    margin-bottom: 0;
  }

  &__time-n-place-icon {
    display: inline-block;
    vertical-align: middle;
    margin-right: 15px;
  }

  &__speaker-name {
    margin: 0 0 0 13px;
    font-style: normal;
    font-weight: 500;
    font-size: 1rem;
    line-height: 19px;
    color: $darkgray;
  }

  &__rating {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__stars-wrapper {
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;
      min-width: 340px;
      max-width: 340px;

      &__star {
        height: 30px;
        background-repeat: no-repeat;
        border: none;
        background-color: transparent;

        &:hover {
          background-color: transparent;
          cursor: default;
        }

        &:hover:not([disabled]) {
          background-image: url('../assets/images/icons/star-full-violet.svg');
          cursor: pointer;
          background-color: transparent;
        }

        &:hover:not([disabled]) ~ button {
          background-image: url('../assets/images/icons/star-full-violet.svg');
          cursor: pointer;
          background-repeat: no-repeat;
          background-color: transparent;
        }
      }
    }

    &__numbers {
      display: flex;
      margin: auto;
      width: 65px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;

      span {
        margin-right: 5px;
      }

      &:hover {
        cursor: default;
      }
    }
    &__first {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 30px;
      margin-right: 20px;
    }

    &__close {
      padding: 0;
      border: none;
      background-color: transparent;
      width: 30px;
      height: 30px;

      svg {
        width: 16px;
        height: 16px;
        vertical-align: middle;
      }
      &:hover {
        background-color: transparent;
        svg > path {
          fill: $dark-red;
        }
      }
    }
  }

  &__pb30 {
    padding-bottom: 30px;
  }
}

.add-file {
  .MuiDialog-paperWidthSm {
    width: 700px;
    max-width: 700px;
  }

  &__no-files {
    margin: auto;
    font-size: 14px;
  }

  &__table {
    .MuiTableCell-head {
      font-size: $font-size-text-l;
      color: $gray-01;
      padding: 6px 15px;
    }

    .MuiTableCell-body {
      font-size: $font-size-text-m;
      color: $gray-02;
      padding: 6px 15px;
    }
  }

  &__action-panel {
    padding-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .MuiButton-outlined {
      text-transform: none;
      border-radius: 13px;
      padding: 5px 10px;
      margin-left: 10px;
      border-color: $purple;
      font-size: 13px;
    }

    &__button {
      width: fit-content;
      height: 26px;
    }
  }

  &__cell {
    &__name-cell {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      max-height: 30px;
      max-width: 300px;
    }

    &__buttons-cell {
      min-width: 60px;
      > button {
        height: 30px;
        width: 30px;
        padding: 7px;
      }
    }
  }

  &__dialog {
    padding: 10px 24px 24px !important;

    &__header {
      padding-right: 0 !important;
      margin: 0 24px !important;
      border-bottom: 1px solid $gray-08;

      > h6 {
        width: 100%;
        display: flex;
        justify-content: space-between;

        > button {
          width: 38px;
        }
      }
    }

    &__content {
      display: flex;
      width: 100%;

      .image-dropzone {
        min-width: 250px;
        max-width: 250px;
        min-height: 250px;
        max-height: 250px;
        margin-top: 14px;

        .image-dropzone__upload-container {
          height: 250px;

          .image-dropzone__explanation {
            width: 235px;
            max-width: 235px;
          }
        }
      }

      &__list-title {
        min-width: 396px;
        text-align: center;
        font-size: 18px;
      }

      &__list {
        @include ul-clear;
        padding-left: 30px;

        &__loader {
          margin-top: -8px;

          > svg {
            height: 50px;
            width: 100%;
          }
        }
      }
    }
  }
}

.publish-button {
  background-origin: border-box;
  border-color: transparent;
  background-color: $purple;
  background-image: -webkit-linear-gradient(64.47deg, $purple 16.63%, #314ad7 83.37%);
  background-image: linear-gradient(25.53deg, $purple 16.63%, #314ad7 83.37%);
  color: $white;
  width: 105px !important;
}

.publish-button:hover {
  text-decoration: none;
  box-shadow: 0 4px 8px rgba(60, 72, 97, 0.3);
  background-color: $gray-07;
}

.go-back-button {
  border-color: $gray-08;
  background-color: transparent;
  color: $gray-02;
  width: 74px !important;
}

.go-back-button:hover {
  background-color: rgba(132, 143, 165, 0.1);
}

.go-to-meetup-preview {
  background-color: $purple;
  background-image: -webkit-linear-gradient(64.47deg, $purple 16.63%, #314ad7 83.37%);
  background-image: linear-gradient(25.53deg, $purple 16.63%, #314ad7 83.37%);
  color: white !important;
  margin-left: 10px !important;
}

.joined-to-meetup-preview {
  background-color: #9370db !important;
  color: white !important;
  margin-left: 10px !important;
}

.go-to-meetup {
  position: absolute;
  top: 77%;
  left: 75%;
}

.go-to-meetup button {
  padding: 3px !important;
  border-radius: 60px !important;
  width: 120px !important;
  color: white !important;
}

.go-to-meetup-card {
  background-color: $purple;
  background-image: -webkit-linear-gradient(64.47deg, $purple 16.63%, #314ad7 83.37%);
  background-image: linear-gradient(25.53deg, $purple 16.63%, #314ad7 83.37%);
}

.joined-to-meetup-card {
  background-color: #9370db !important;
}

.text-center {
  text-align: center;
}
