.DraftEditor-root {
  position: relative;
  padding: 0;
  font-size: 14px;
  border: none;
  background: inherit;

  .public-DraftEditorPlaceholder-inner {
    position: absolute;
    margin-top: 2px;
    color: $gray-03;
  }
}

.DraftEditor-editor {
  cursor: text;
  margin-top: 10px;
  font-size: 16px;

  .public-DraftEditorPlaceholder-root,
  .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
  }

  .public-DraftEditor-content {
    min-height: 100px;
  }

  .public-DraftStyleDefault-pre {
    padding: 20px;
    font-size: 16px;
  }
}

.DraftEditor-hidePlaceholder {
  .public-DraftEditorPlaceholder-root {
    display: none;
  }
}

.DraftEditor-editorContainer {
  position: relative;
  z-index: 1;
  font-size: $font-size-text-xl;
  text-align: left;

  .public-DraftStyleDefault-block {
    margin-bottom: 10px;
  }

  blockquote {
    margin: 16px 0;
    padding: 10px 20px;
    font-style: italic;
    border-left: 5px solid $gray-08;
    color: $gray-02;
  }
}

.DraftEditor-controls {
  margin-bottom: 5px;
  font-size: 14px;
  user-select: none;
}

.DraftEditor-styleButton {
  display: inline-block;
  margin-right: 16px;
  padding: 2px 0;
  cursor: pointer;
}

.DraftEditor {
  &__container.edit {
    background-color: rgba(244, 246, 252, 0.4);
    border: 1px solid $gray-08;
    border-radius: 4px;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: $white;
    pointer-events: auto;
    outline: none;
    word-break: break-word;

    button {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 30px;
      height: 30px;
      margin: 0 2px;
      font-size: 0.8em;
      border-radius: 0;
      border: none;
      background: none;
      color: $gray-02;

      &:hover,
      &:active {
        color: $white;
        background-color: $purple;
      }

      b {
        display: none;
      }
      svg {
        font-size: $font-size-title-m;
      }
    }

    .selected {
      color: $darkgray;
    }

    &:hover,
    &:active,
    &:focus {
      border-width: 1px;
      border-color: $purple;
    }
  }

  &__editor.edit {
    max-height: 400px;
    margin: 0;
    overflow-y: auto;
    width: 100%;
    & .DraftEditor-root {
      padding: 10px 25px 5px 13px;
    }
  }

  &__toolbar {
    display: none;
  }

  &__toolbar.edit {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    border-bottom: 1px solid $gray-08;
  }
  &__description-counter {
    display: flex;
    justify-content: flex-end;
    font-size: $font-size-text-s;
    color: $gray-03;
    opacity: 0;
    margin-bottom: 15px;
  }
  &__counter__show {
    opacity: 1;
  }
}

.change-link {
  position: relative;
  width: 30px;
  height: 30px;
  margin: 0 2px;

  button {
    width: 100%;
    margin-left: 0 !important;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}

.inline-link {
  color: $darkblue;
  text-decoration: none;

  &:hover,
  &:active {
    text-decoration: underline;
  }
}

.divider {
  display: flex;
  border-right: 1px solid $gray-08;
  min-height: 20px;
  margin: 0 8px 0 8px;
}

.container-height {
  min-height: 12em;
}
