@mixin card {
  background-color: $white;
  border-radius: 6px;
  box-shadow: 0 4px 9px rgba(194, 203, 218, 0.25);
  margin-bottom: 16px;
  color: $gray-06;
}

@mixin ul-clear() {
  margin: 0;
  padding: 0;
  list-style: none;
}
