.notification-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0.25px;
    font-size: 0.75rem;
    padding: 0 10px;
    color: $darkgray;
    border-bottom: 1px solid $gray-08;
    &__new {
      color: $gray-02;
      @-moz-document url-prefix() {
        margin-right: 20px;
      }
    }
  }
  &__list {
    margin: 0;
    padding: 0 15px 10px;
  }
  &__icon {
    width: 40px;
    padding: 5px;
  }
  &__read {
    opacity: 0.45;
  }
  &__card {
    display: flex;
    min-width: 400px;
    background-color: $lightgray;
    padding: 10px 0;
    height: 40px;
    border-bottom: 1px solid $gray-08;
    &__main-block {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-left: 10px;
    }
    &__date {
      color: $gray-02;
      font-size: 0.75rem;
      padding-right: 5px;
      @-moz-document url-prefix() {
        white-space: nowrap;
        padding-right: 20px;
      }
    }
    &__text-block {
      cursor: pointer;
      font-size: 0.75rem;
      display: block;
      line-height: 18px;
      max-width: 300px;
      &__header {
        font-weight: bold;
      }
      &__subject {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &__no-click {
        cursor: default;
      }
    }
  }
}
