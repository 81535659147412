.App .card {
  width: 100%;
  margin: 0 0 24px;
  background: white;
  box-shadow: 0 4px 7px rgba(215, 221, 232, 0.398055);
  border-radius: $border-radius;
  overflow: visible;
  box-sizing: border-box;

  &_form {
    padding: 40px;
    box-shadow: 0 6px 12px $whitegray;
  }

  &_memo-form {
    padding: 12px 40px 40px;
  }

  &_controls {
    margin-bottom: 0;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
  }

  & > div {
    padding: 0;
  }

  &_no-padding {
    padding: 0;
  }

  &_with-padding-bottom {
    padding-bottom: 30px;
  }

  &_time-n-place {
    list-style: none;
  }

  &_speaker {
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    & .button:first-child:not(:last-child) {
      margin-right: auto;
    }
  }

  &__heading {
    margin: 0 0 0 5px;
    font-style: normal;
    font-weight: normal;
    font-size: $font-size-title-s;
    line-height: 24px;
    color: $gray-02;
  }
}
