.news-card {
  @include card;
  display: flex;
  flex-direction: row;
  margin-left: 8px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 136px;
    padding-top: 10px;

    &__subject {
      margin: 0 5px 0 20px;
      font-weight: 500;
      font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
      font-size: $font-size-title-m;
      color: $black;
      line-height: 20px;
      letter-spacing: -0.3px;
      overflow-wrap: break-word;
      max-height: 40px;
      overflow: hidden;
    }

    &__buttons {
      display: flex;
      align-items: center;
      margin: 0;

      button {
        width: min-content;
        padding: 10px;
      }
    }
  }

  &__body {
    padding-left: 140px;
  }
  &__footer {
    padding-left: 140px;
    padding-right: 10px;
    padding-top: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &__date {
      padding: 15px 20px;
      color: $gray-03;
      font-size: $font-size-text-l;
    }
    &__count {
      padding-right: 10px;
      color: $gray-03;
      font-size: $font-size-text-l;
    }
    &__count.hidden {
      display: none;
    }
    &__likes {
      display: flex;
      align-items: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    min-height: 160px;
    border: 1px solid transparent;

    &__excerpt {
      display: flex;
      padding: 0 20px 15px 20px;
      font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
      font-size: $font-size-text-l;
      line-height: 21px;
      color: $gray-02;
      max-height: calc(#{$font-size-text-l} * 2);
      overflow: hidden;
      margin-bottom: 0;
      margin-top: 10px;
    }
  }

  &__topic-img-wrapper {
    position: absolute;
    left: -8px;
    top: -8px;
    width: 144px;
    height: 144px;
    border-radius: $border-radius;
    background-color: #f1eee9;
    box-shadow: 3px 5px 8px rgba(105, 112, 127, 0.34);

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: $border-radius;
    }
  }
}
