.meetup-card {
  @include card;
  position: relative;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    padding: 0;

    &__title {
      margin: 0 2px;
      font-style: normal;
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 17px;
      letter-spacing: 0.25px;
      color: $gray-01;
    }

    &__info {
      display: flex;
      width: 100%;
      margin: 0;
      padding: 15px 0 0 20px;
      overflow: hidden;

      &__item {
        position: relative;

        &-divider {
          margin: 0 7px;
        }

        &:not(:first-child) {
          display: inline-block;
          width: 65%;
          position: relative;
          max-width: 50%;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    &__buttons {
      display: flex;
      align-items: center;
      margin: 0;
      padding: 10px 0 0 0;

      button {
        width: min-content;
        margin-right: 10px;
        padding: 10px;
      }
    }

    &__button:hover {
      background-color: rgba(0, 0, 0, 0.08);
    }
  }

  &__content {
    margin-bottom: 15px;
    padding: 0 20px;

    &:last-child {
      padding-bottom: 0;
    }

    &__heading {
      margin-bottom: 12px;
      max-height: 60px;
      height: 100%;
      font-family: 'Rubik', 'Roboto', 'Helvetica', 'Arial', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: $font-size-text-xxl;
      line-height: 20px;
      color: $darkgray;
      word-break: break-word;
      overflow: hidden;
    }

    &__text {
      font-style: normal;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 20px;
      color: $gray-02;
      word-break: break-word;
      max-height: 40px;
      overflow: hidden;
    }
  }

  &__footer {
    display: flex;
    align-items: center;
    padding: 0 20px 15px 20px;
    justify-content: space-between;

    &__participants {
      display: flex;
      font-size: $font-size-text-l;

      &__image,
      &__count {
        margin-right: 5px;
      }
    }
  }
}

.green-border {
  border-left: 8px solid $lightgreen;
}

.meetup-card:hover {
  box-shadow: rgba(60, 64, 67, 0.3) 0 1px 2px 0, rgba(60, 64, 67, 0.15) 0 2px 6px 2px;
}
