.App-header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 1000;
  align-items: center;
  justify-content: space-between;
  height: 70px;
  background-image: $gradient-01;
  padding-left: 3%;
  padding-right: 3%;

  &__logo {
    padding: 10px 0;
  }

  &__logo-container {
    width: 30%;
  }

  .margin-60 {
    margin-right: 60px !important;
  }

  &__links {
    width: 30%;
    display: flex;
    justify-content: center;
    & > a {
      padding: 0 30px;
    }
  }

  &__link {
    width: auto !important;
    color: $white !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 19px !important;
    font-variant: normal !important;
    text-transform: none !important;
    border-radius: $border-radius $border-radius 0 0 !important;
  }
}

.active-menu {
  border-bottom: 2px solid $white !important;
}
.notifications {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;
  max-width: 48px;
  margin-right: 10px;
  & > button {
    width: 40px;
    height: 40px;
    padding: 0;
    & > span {
      width: 35px;
      height: 35px;
    }
  }
  &__unread {
    background-color: $red;
    color: $white;
    border-radius: 8px;
    min-width: 15px;
    height: 15px;
    font-size: 11px;
    position: relative;
    top: -12px;
    left: -15px;
    display: flex;
    justify-content: space-around;
    line-height: 10px;
    align-items: center;
    padding-right: 1px;
  }
}

.notificationsPopover {
  .MuiPopover-paper {
    border-radius: 10px !important;
    background-color: $lightgray !important;
  }
}

.avatar {
  display: flex;
  width: 30%;
  justify-content: center;
  align-items: center;

  &__name,
  &__surname {
    margin-right: 6px;
    color: $white;
    font-size: 1rem;
    font-weight: 300;
    letter-spacing: 0.25px;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;
  }

  &__image {
    min-width: 40px;
    line-height: 19px;
    letter-spacing: 0.25px;
    background-color: transparent !important;
    overflow: hidden;
    margin-right: 30px;
    font-family: Rubik, Roboto, Helvetica, Arial, sans-serif;

    & > div {
      font-size: 1rem !important;
    }

    & div:not(.avatar__image) {
      background-color: rgba(255, 255, 255, 0.45);
    }
  }
}
.notifications-list {
  display: flex;
  min-width: 400px;
  max-height: 405px;
  background-color: $lightgray;
}

@media screen and (max-width: $app-medium-resolution) {
  .avatar {
    width: min-content;

    &__name,
    &__surname {
      display: none;
    }
  }
}
