.labels {
  display: flex;
  justify-content: space-between;

  .input-label {
    min-width: 225px;
  }
}

.date-pickers {
  margin-bottom: 25px;
  display: flex;
  justify-content: space-between;

  &__clear-button {
    width: 25px;
    height: 25px;
  }
  &__button-container {
    display: flex;
    padding-right: 10px;
    align-items: flex-end;
  }

  &__start-date,
  &__end-date {
    width: 225px;
  }

  &__end-date.hidden {
    visibility: hidden;
  }

  .MuiInputBase-root {
    min-width: 200px;
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: $purple;
  }
  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-width: 1px !important;
  }

  .MuiInputBase-input {
    color: $gray-01 !important;
    font-size: $font-size-title-s;
    padding: 10px;
  }

  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }

  .MuiIconButton-root {
    padding: 5px;
  }

  .MuiIconButton-label {
    width: auto;
  }

  .MuiInputLabel-formControl {
    color: $gray-02;
    font-size: $font-size-title-s;
  }

  .MuiInputLabel-outlined {
    z-index: 1;
    transform: translate(12px, 14px) scale(1);
  }

  .MuiOutlinedInput-adornedEnd {
    background-color: rgba(244, 246, 252, 0.4);
  }

  .MuiFormLabel-root.Mui-focused {
    color: $purple;
  }

  .iconCalendar {
    color: $purple;
    transition-property: color;
    transition-duration: 0.3s;
  }

  .react-datepicker {
    border-color: $gray-08;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    border-radius: 4px;

    &__day--disabled {
      color: $gray-01 !important;
      opacity: 0.2 !important;
    }

    &__triangle {
      left: 185px;
    }

    &__header {
      background-color: $white !important;
      border-bottom: 1px solid $gray-08;
      margin: 8px;
    }

    &__day-names {
      margin-top: 16px;
      border-top: 1px solid $gray-08;
    }

    &__header--time {
      padding-bottom: 16px;
      margin-bottom: 0;
    }

    &__month-container {
      border-right: 1px solid $gray-08;
      height: 100%;
    }

    .react-datepicker__month {
      margin: 0;
    }

    &__time-container {
      border-left: none;
      width: 150px;
    }

    &__time &__time-box {
      width: 100%;
      overflow-x: hidden;
      margin: 0 auto;
      text-align: center;
    }

    &__time-list-item {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px !important;
    }

    &__navigation {
      top: 17px !important;
    }

    &__navigation--next--with-time:not(&__navigation--next--with-today-button) {
      right: 160px !important;
    }

    &__day {
      color: $gray-01 !important;
    }

    &__time {
      color: $gray-01 !important;
    }

    &__day--selected {
      background: linear-gradient(166.72deg, $purple -2.22%, $blue 103.24%);
      color: $white !important;
    }

    &__day--keyboard-selected {
      background: linear-gradient(166.72deg, $purple -2.22%, $blue 103.24%);
      color: $white !important;
    }

    &__day--outside-month {
      color: $gray-01 !important;
      opacity: 0.2 !important;
    }
  }

  .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    width: auto !important;
  }

  .MuiInputBase-input {
    color: $gray-01;
  }

  li.react-datepicker__time-list-item--selected {
    background: linear-gradient(166.72deg, $purple -2.22%, $blue 103.24%);
    color: $white !important;
  }

  li.react-datepicker__time-list-item--disabled {
    display: none;
  }

  .react-datepicker-popper {
    z-index: 100 !important;
  }
}
