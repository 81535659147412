.ButtonGroup {
  display: inline-block;
  vertical-align: top;
  margin: 0 5px 0 4px;
  white-space: nowrap;

  &__root {
    white-space: nowrap;
  }
}

.ButtonWrap__root {
  display: inline-block;
  position: relative;
  z-index: 10;
  margin-right: -1px;
}
