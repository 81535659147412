.App {
  .form__field {
    background-color: rgba(244, 246, 252, 0.4);

    &:last-child {
      margin-bottom: 0;
    }

    .form__field-label {
      color: $gray-03;
      font-size: $font-size-text-l;
      z-index: 0;
      transform: translate(14px, 13px) scale(1);

      &.form__field-label_shrink {
        transform: translate(13px, -4px) scale(0.75);
        background-color: $white;
      }

      &.form__field-label_focused {
        color: $purple;
      }

      &.form__field-label_disabled {
        color: $gray-03;
      }
    }

    .form__field-input-wrapper {
      padding: 0;

      fieldset {
        border-color: $gray-08;
      }

      &.form__field-input-wrapper_disabled {
        fieldset {
          border-color: $gray-08;
        }
      }

      &.form__field-input-wrapper_focused {
        fieldset {
          border-width: 1px;
          border-color: $purple;
        }
      }
    }

    .form__field-input {
      box-sizing: border-box;
      padding: 10.5px 14px;
      min-height: $input-height;
      max-height: $input-height;
      font-size: $font-size-text-l;
      color: $gray-01;
    }

    .form__field-input-wrapper:hover:not(.form__field-input-wrapper_disabled):not(.form__field-input-wrapper_focused):not(.form__field-input-wrapper_error) {
      fieldset {
        border-color: $purple;
      }
    }

    &__counter {
      display: flex;
      justify-content: flex-end;
      font-size: $font-size-text-s;
      color: $gray-03;
      opacity: 0;
    }

    &__show {
      opacity: 1;
    }

    &__hide {
      opacity: 0;
    }

    &__invalid {
      color: $red;
    }
  }
}
