.image-loaded__heading {
  margin: 0 0 5px;
  color: $gray-03;
  font-size: $font-size-text-m;
}

.image-loaded__container {
  position: relative;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.8);
  border: 1px dashed $gray-07;
  border-radius: $border-radius;

  &--loaded {
    padding: 0;
    border: none;
    min-height: 264px;
  }
}

.image-loaded__image {
  margin-right: 10px;

  &--loaded {
    width: 100%;
    height: 264px;
    margin-right: 0;
    object-fit: cover;
    border-radius: $border-radius;
  }
}

.image-loaded__info {
  margin-right: 10px;
  max-width: calc(100% - 20px - 32px - 24px);

  &--loaded {
    display: none;
  }
}

.image-loaded__image-title {
  margin: 0;
  color: $black;
  font-size: $font-size-text-l;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.image-loaded__image-size {
  margin: 0;
  color: $gray-03;
  font-size: $font-size-text-s;
}

.image-loaded__icon {
  color: $gray-06;
  cursor: pointer;

  &--loaded {
    position: absolute;
    right: 0;
    top: -25px;
  }
}
