@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Regular.ttf') format('truetype'),
    url('../assets/fonts/Rubik-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('../assets/fonts/Rubik-Medium.ttf') format('truetype'),
    url('../assets/fonts/Rubik-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('../assets/fonts/Roboto-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
