.App .button {
  box-sizing: border-box;
  margin: 0;
  padding: 11px 15px;
  border-radius: $border-radius;
  border-style: solid;
  border-width: 1px;
  box-shadow: none;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 500;
  font-size: $font-size-text-l;
  line-height: 16px;
  width: 152px;
  text-transform: none;
  transition-property: color, background-color, box-shadow;
  transition-duration: 0.4s;

  &__label {
    position: relative;
    z-index: 0;
  }

  &__ripple {
    top: -1px;
    left: -1px;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
  }

  &__ripple-visible {
    opacity: 1;
  }
  &__no-outline {
    outline: none;
  }

  &_link {
    text-align: center;

    &:hover {
      text-decoration: none;
    }
  }

  &_small {
    width: 128px;
  }

  &_wide {
    width: 168px;
  }

  &_contained-violet {
    background-origin: border-box;
    border-color: transparent;
    background-color: $purple;
    background-image: linear-gradient(25.53deg, #8065ec 16.63%, #314ad7 83.37%);
    color: rgba(255, 255, 255, 1);

    &:hover {
      box-shadow: 0 4px 8px rgba(60, 72, 97, 0.3);

      & .button__ripple-child {
        background-color: $darkblue;
      }
    }

    &:active {
      box-shadow: 0 4px 8px rgba(60, 72, 97, 0.2);
    }
  }

  &_outlined-grey {
    border-color: $gray-08;
    background-color: transparent;
    color: $gray-08;

    &:hover {
      background-color: rgba(132, 143, 165, 0.1);
    }

    &:active {
      border-color: $gray-04;

      & .button__ripple-child {
        background-color: rgba(132, 143, 165, 0.2);
      }
    }
  }

  &_outlined-violet {
    border-color: $purple;
    background-color: transparent;
    color: $purple;

    &:hover {
      background-color: rgba(128, 101, 236, 0.2);
    }

    &:active {
      & .button__ripple-child {
        background-color: rgba(128, 101, 236, 0.2);
      }
    }
  }

  &.add-file-button {
    width: 123px;
    padding-left: 2px;
    padding-right: 2px;
  }

  &.delete-button {
    width: 65px;
  }

  &.xs_button {
    width: 80px;
  }

  &.s_button {
    width: 95px;
  }

  &.m_button {
    width: 110px;
  }

  &.l_button {
    width: 140px;
  }

  &.xl_button {
    width: 191px !important;
  }

  &.draft-button {
    width: 120px;
  }

  &.edit-button {
    width: 115px;
  }

  &.admin-button {
    width: 190px;
  }

  &.like-button {
    background-color: $purple;
    background-image: -webkit-linear-gradient(64.47deg, $purple 16.63%, #314ad7 83.37%);
    background-image: linear-gradient(25.53deg, $purple 16.63%, #314ad7 83.37%);
  }

  &.dislike-button {
    background-color: #9370db;
    color: #ffffff;
  }

  &.button_disabled {
    border-color: $gray-04;
    background: $gray-04;
    color: $white;
  }

  &.focus_visible {
    border: none;
    outline: -webkit-focus-ring-color auto 1px;
    & .button__ripple-child {
      display: none;
    }
  }
}

@keyframes mui-ripple-enter {
  0% {
    transform: scale(0);
    opacity: 0.1;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
