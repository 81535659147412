$white: #ffffff;
$whitegray: #e2e7ef;
$black: #3c4861;
$purple: #8065ec;
$blue: #314ad7;
$lightblue: #7989e7;
$orange: #ffc121;
$dark-orange: #fb9700;
$darkblue: #2a40bf;
$gray-01: #5b6887;
$gray-02: #848fa5;
$gray-03: #aab6ca;
$gray-04: #c2cbda;
$gray-05: #c4c4c4;
$gray-06: #6c757d;
$gray-07: #d5d5d5;
$gray-08: #d5dce7;
$green: #2bbc7e;
$dark-green: #009a73;
$red: #ff380c;
$dark-red: #ef2f00;
$lightgreen: #80cbc4;
$lightgray: #f4f6fc;
$darkgray: #3c4861;
$gradient-01: linear-gradient(5.87deg, $purple 16.63%, #314ad7 83.37%);

/* typography scheme */
$font-size-title-xl: 1.5rem; /* 24px */
$font-size-title-l: 1.375rem; /* 22px */
$font-size-title-m: 1.125rem; /* 18px */
$font-size-title-s: 0.875rem; /* 14px */

$font-size-text-xxl: 17px;
$font-size-text-xl: 1rem; /* 16px */
$font-size-text-l: 0.875rem; /* 14px */
$font-size-text-m: 0.8125rem; /* 13px */
$font-size-text-s: 0.625rem; /* 10px */

$app-content-width: 550px;

$app-medium-resolution: 990px;

$input-height: 40px;
$border-radius: 5px;

/* fonts */
$font-family-roboto: Roboto, Helvetica, Arial, sans-serif;
$font-family-rubik: Rubik, Helvetica, Arial, sans-serif;
